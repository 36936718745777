<template>
  <div>
    <Navbar/>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
  import Navbar from './components/Navbar.vue';
  import Footer from './components/Footer.vue';

  export default{
    components:{
      Navbar,
      Footer
    }
  }
</script>

<style>
  
  ; @import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@300;400;700&display=swap');

  :root {
    --verde: #00B386;
    --branco: #FFFFFF;
    --cinza-claro: #F0F0F0;
    --cinza: #5f5f5f;
    --preto: #000000;
  }

  
  * {
    font-family: Helvetica Bold, Arial ;
    font-style: normal;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }


</style>