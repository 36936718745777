<template>
  <div class="home-container">
    <div class="home-aside-container">
      <div class="home-aside-title">
        <h2>Título do artigo</h2>
      </div>
      <!-- home-aside-title  -->
      <div class="home-aside-avatar-author">
        <img
          id="aside-avatar-author"
          alt="Imagem autor"
          src="../assets/profile.svg"
        />
      </div>
      <!-- home-aside-avatar-author  -->
      <div class="home-aside-autor">
        <h3>Autor: Dr. ipsum Lorem</h3>
      </div>
      <!-- home-aside-autor  -->
      <div class="home-aside-data">
        <p>
          ipsum Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cumque
          nihil et, quos culpa quo itaque harum, sit reprehenderit aperiam
          minima nam! Vitae ducimus facere rem quam quod sint sed rerum. ipsum
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cumque nihil
          et, quos culpa quo itaque harum, sit reprehenderit aperiam minima nam!
          Vitae ducimus facere rem quam quod sint sed rerum.
        </p>
      </div>
      <!-- home-aside-data  -->
    </div>
    <!-- home-aside-container  -->

    <div class="home-body-container">
      <div class="home-steps-consultation-container">
        <h2 class="home-steps-consultation-title">
          Como funciona a consulta?
        </h2>
        <!-- home-steps-consultation-title  -->
        <div class="home-steps-progress">
          <div
            v-for="(step, index) in steps"
            :key="index"
            class="step"
            :class="{
              active: currentStep >= index,
              completed: currentStep > index,
              current: currentStep === index,
            }"
          >
            <div class="home-step-icon">
              <transition name="fade" mode="out-in">
                <div class="home-step-number" v-if="currentStep < index">
                  {{ index + 1 }}
                </div>
                <!-- home-step-number  -->
                <div
                  class="home-step-current"
                  v-else-if="currentStep === index"
                >
                  {{ index + 1 }}
                </div>
                <!-- home-step-current  -->
                <div class="home-step-check" v-else>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="3"
                  >
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
                <!-- home-step-check  -->
              </transition>
            </div>
            <!-- home-step-icon  -->
            <div class="home-step-label">
              <span>{{ step.label }}</span>
            </div>
            <!-- home-step-label  -->
            <div
              class="home-step-connector"
              v-if="index < steps.length - 1"
            ></div>
            <!-- home-step-connector  -->
          </div>
        </div>
        <!-- home-steps-progress  -->
        <div class="home-current-step-description">
          <transition name="slide-fade" mode="out-in">
            <div
              v-if="steps[currentStep]"
              :key="currentStep"
              class="home-step-description-text"
            >
              {{ steps[currentStep].description }}
            </div>
            <!-- home-step-description-text  -->
          </transition>
        </div>
        <!-- home-current-step-description  -->
      </div>
      <!-- home-steps-consultation-container  -->

      <div class="home-specialists-container">
        <h2 class="specialists-title">
          Nossos especialistas
        </h2>
        <!-- specialists-title  -->
        <div class="specialists-wrapper">
          <swiper
            :modules="modules"
            :slides-per-view="specialistsPerView"
            :space-between="30"
            :pagination="{
              clickable: true,
              el: '.specialists-pagination',
            }"
            :navigation="{
              nextEl: '.specialists-button-next',
              prevEl: '.specialists-button-prev',
            }"
            :loop="true"
          >
            <swiper-slide
              v-for="specialist in specialists"
              :key="specialist.id"
            >
              <div class="specialists-card">
                <div class="specialist-data">
                  <div class="specialist-header">
                    <div class="specialist-avatar">
                      <img
                        :src="specialist.avatar"
                        alt="Foto do especialista"
                      />
                    </div>
                    <!-- specialist-avatar  -->
                    <div class="specialist-description">
                      <h3>{{ specialist.name }}</h3>
                      <span id="specialty">{{ specialist.specialty }}</span>
                      <span id="crm">CRM: {{ specialist.crm }}</span>
                    </div>
                    <!-- specialist-description  -->
                  </div>
                  <!-- specialists-header  -->
                  <div class="specialists-info">
                    <span id="specialist-formation"
                      ><strong>Formação:</strong>
                      {{ specialist.formation }}</span
                    >
                    <span id="specialist-description">{{
                      specialist.description
                    }}</span>
                    <span id="specialist-formation"
                      ><strong>Valor da consulta:</strong> R$
                      {{ specialist.cost }}</span
                    >
                    <button class="btn-consultar">
                      Quero consultar com {{ specialist.name }}
                    </button>
                  </div>
                  <!-- specialists-info  -->
                </div>
                <!-- specialist-data  -->
                <div class="specialist-schedule">
                  <img
                    src="../assets/agenda.png"
                    alt="Agenda do especialista"
                  />
                </div>
                <!-- specialist-schedule  -->
              </div>
              <!-- specialists-card  -->
            </swiper-slide>
          </swiper>

          <div class="specialists-pagination"></div>
          <div class="specialists-navigation">
            <div class="specialists-button-prev">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="15 18 9 12 15 6"></polyline>
              </svg>
            </div>
            <!-- specialists-button-prev  -->
            <div class="specialists-button-next">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </div>
            <!-- specialists-button-next  -->
          </div>
          <!-- specialists-navigation  -->
        </div>
        <!-- specialists-wrapper  -->
      </div>
      <!-- home-specialists-container -->

      <div class="home-testimonials-container">
        <h2 class="home-testimonials-title">O que nossos pacientes dizem</h2>

        <div class="home-testimonials-wrapper">
          <swiper
            :modules="modules"
            :slides-per-view="testimonialsPerView"
            :space-between="30"
            :pagination="{
              clickable: true,
              el: '.testimonials-pagination',
            }"
            :navigation="{
              nextEl: '.testimonials-button-next',
              prevEl: '.testimonials-button-prev',
            }"
            :loop="true"
          >
            <swiper-slide
              v-for="testimonial in testimonials"
              :key="testimonial.id"
            >
              <div class="testimonials-card">
                <div class="testimonials-stars">
                  <span v-for="n in 5" :key="n" class="star">
                    <i
                      :class="
                        n <= testimonial.stars ? 'star-filled' : 'star-empty'
                      "
                    ></i>
                  </span>
                </div>
                <p class="testimonials-text">{{ testimonial.text }}</p>
                <div class="testimonials-author">
                  <div class="avatar">
                    <img src="../assets/profile.svg" alt="Avatar do cliente" />
                  </div>
                  <div>
                    <h4>{{ testimonial.name }}</h4>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="testimonials-pagination"></div>
          <div class="testimonials-navigation">
            <div class="testimonials-button-prev">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="15 18 9 12 15 6"></polyline>
              </svg>
            </div>
            <div class="testimonials-button-next">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <!-- home-testimonials-wrapper  -->
      <div class="faq-container">
        <h2 class="faq-container-title">
          Ficou com alguma dúvida?<br>Veja as perguntas mais feitas em nossa
          plataforma
        </h2>

        <div
          class="faq-question"
          v-for="(question, index) in faq"
          :key="index"
          @click="toggleFAQ(index)"
        >
          <span class="question">{{ question.pergunta }}</span>
          <span class="icone-faq">
            <i
              :class="{
                'fa-solid fa-plus': !question.isOpen,
                'fa-solid fa-minus': question.isOpen,
              }"
            ></i>
          </span>

          <div v-if="question.isOpen" class="faq-answer">
            <p>{{ question.resposta }}</p>
          </div>
        </div>
      </div>
      <!-- home-FAQ-container  -->
    </div>
    <!-- home-body-container  -->
  </div>
  <!-- </div>  -->
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export default {
  name: "AnimatedStepsIndicator",

  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      currentStep: 0,
      intervalId: null,
      stepDuration: 5000, // Duração de cada passo

      steps: [
        {
          label: "Cadastro",
          description:
            "Preencha seus dados pessoais para acessar o sistema de atendimento",
        },
        {
          label: "Informações Clínicas",
          description:
            "Responda algumas perguntas básicas sobre sua saúde para que seu médico(a) possa te conhecer melhor",
        },
        {
          label: "Especialidade e Médico",
          description:
            "Escolha a especialidade e o médico(a) que melhor atendem às suas necessidades",
        },
        {
          label: "Pagamento",
          description:
            "Realize o pagamento da sua consulta de forma segura e prática",
        },
        {
          label: "Consulta",
          description:
            "Confirme os detalhes da sua consulta e prepare-se para o atendimento",
        },
      ],

      // Carrossel de médicos
      specialistsPerView: 1,
      modules: [Pagination, Navigation], // Movido para data() para acessibilidade
      specialists: [
        {
          id: 1,
          name: "Dra. Ana Silva",
          specialty: "Urologia adulto",
          crm: "123456-SP",
          formation: "Universidade de São Paulo",
          description:
            "ipsum Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cumque nihil et, quos culpa quo itaque harum, sit reprehenderit aperiam minima nam! Vitae ducimus facere rem quam quod sint sed rerum.",
          cost: 150,
          avatar: require("../assets/profile.svg"), // Usando require para importação
        },
        {
          id: 2,
          name: "Dr. Carlos Oliveira",
          specialty: "Urologia infantil",
          crm: "234567-RJ",
          formation: "Universidade Federal do Rio de Janeiro",
          description:
            "ipsum Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cumque nihil et, quos culpa quo itaque harum, sit reprehenderit aperiam minima nam! Vitae ducimus facere rem quam quod sint sed rerum.",
          cost: 150,
          avatar: require("../assets/profile.svg"),
        },
        {
          id: 3,
          name: "Dra. Mariana Santos",
          specialty: "Urologia adulto",
          crm: "345678-MG",
          formation: "Universidade Federal de Minas Gerais",
          description:
            "ipsum Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cumque nihil et, quos culpa quo itaque harum, sit reprehenderit aperiam minima nam! Vitae ducimus facere rem quam quod sint sed rerum.",
          cost: 150,
          avatar: require("../assets/profile.svg"),
        },
        {
          id: 4,
          name: "Dr. Rafael Mendes",
          specialty: "Urologia infantil",
          crm: "456789-RS",
          formation: "Universidade Federal do Rio Grande do Sul",
          description:
            "ipsum Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cumque nihil et, quos culpa quo itaque harum, sit reprehenderit aperiam minima nam! Vitae ducimus facere rem quam quod sint sed rerum.",
          cost: 150,
          avatar: require("../assets/profile.svg"),
        },
        {
          id: 5,
          name: "Dra. Juliana Costa",
          specialty: "Urologia adulto",
          crm: "567890-PR",
          formation: "Universidade Federal do Paraná",
          description:
            "ipsum Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cumque nihil et, quos culpa quo itaque harum, sit reprehenderit aperiam minima nam! Vitae ducimus facere rem quam quod sint sed rerum.",
          cost: 150,
          avatar: require("../assets/profile.svg"),
        },
      ],
      testimonialsPerView: 2,
      testimonials: [
        {
          id: 1,
          name: "João Silva",
          text: "Excelente atendimento! O médico foi muito atencioso e profissional.",
          stars: 5,
          avatar: "../assets/profile.svg",
        },
        {
          id: 2,
          name: "Maria Oliveira",
          text: "Consulta muito satisfatória. O diagnóstico foi preciso.",
          stars: 4,
          avatar: "../assets/profile.svg",
        },
        {
          id: 3,
          name: "Pedro Santos",
          text: "Ótima experiência, recomendo para todos que precisam de consulta!",
          stars: 5,
          avatar: "../assets/profile.svg",
        },
        {
          id: 4,
          name: "Ana Costa",
          text: "Atendimento pessímo! O médico ruim, quero meu dinhheiro de volta!.",
          stars: 0,
          avatar: "../assets/profile.svg",
        },
      ],
      faq: [
        {
          pergunta: "O que é um FAQ?",
          resposta: "FAQ significa 'Perguntas Frequentes'.",
          isOpen: false,
        },
        {
          pergunta: "Como agendar uma consulta?",
          resposta: "Você pode agendar uma consulta acessando nossa plataforma.",
          isOpen: false,
        },
      ],
    };
  },
  mounted() {
    // pagina home não indexada
    document.head.querySelector('meta[name="robots"]')?.setAttribute("content", "noindex, nofollow");
    
    this.startAnimation();

    this.$nextTick(() => {
      this.updateTestimonialsPerView();

    });

    window.addEventListener("resize", this.updateTestimonialsPerView);
  },

  methods: {
    startAnimation() {
      this.currentStep = 0;
      this.intervalId = setInterval(() => {
        if (this.currentStep < this.steps.length - 1) {
          this.currentStep++;
        } else {
          setTimeout(() => {
            this.currentStep = 0;
          }, 1000);
        }
      }, this.stepDuration);
    },
    stopAnimation() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
    },
    restartAnimation() {
      this.stopAnimation();
      this.startAnimation();
    },
    updateTestimonialsPerView() {
      this.testimonialsPerView = 2;
    },
    toggleFAQ(index) {
      this.faq[index].isOpen = !this.faq[index].isOpen;
    },
  },
};
</script>

<style scoped>
.home-container {
  display: flex;
  justify-content: left;
  padding: 0px 0px;
}

.home-aside-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
  min-width: 120px;
  max-width: 250px;
  padding: 10px 10px;
}

.home-aside-title {
  font-size: 0.6em;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
}

.home-aside-avatar-author {
  width: 50%;
  padding-bottom: 10px;
}

.home-aside-autor {
  font-size: 0.6em;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
}

.home-aside-data {
  font-size: 0.8em;
  text-align: justify;
  width: 100%;
}

.home-body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  width: 80%;
  padding: 10px 10px;
}

.home-steps-consultation-container {
  max-width: 800px;
  margin: 0 5px;

}

.home-steps-progress {
  display: flex;
  justify-content: left;

  position: relative;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  flex: 1;
  padding: 0 10px;
}

.home-step-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
  transition: all 0.4s ease;
  position: relative;
}

.step.active .home-step-icon {
  background-color: var(--verde);
  color: white;
}

.step.current .home-step-icon {
  transform: scale(1.1);
  box-shadow: 0 0 0 5px rgba(76, 175, 80, 0.2);
}

.home-step-number,
.home-step-current,
.home-step-check {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-step-number {
  color: #757575;
  font-weight: bold;
}

.home-step-current {
  color: white;
  font-weight: bold;
}

.home-step-check {
  color: white;
}

.home-step-label {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  color: #e0e0e0;
}

.home-step-label span {
  font-size: 0.85rem;
  color: -var(--cinza);
  transition: color 0.3s ease;
  line-height: 1.2;
  font-size: 0.9rem;
}

.step.active .home-step-label span {
  color: var(--verde);
  font-weight: 500;
}

.step.current .home-step-label span {
  font-weight: 600;
}

.home-step-connector {
  position: absolute;
  top: 20px;
  right: -50%;
  width: 100%;
  height: 2px;
  background-color: #e0e0e0;
  z-index: -1;
}

.step.active .home-step-connector {
  background-color: var(--verde);
  transition: background-color 0.6s ease;
}

.home-current-step-description {
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 1.5rem;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.home-step-description-text {
  font-size: 1rem;
  color: var(--verde);
  line-height: 1.5;
}

.home-specialists-container {
  width: 100%;
  padding: 10px 10px;
}

.specialists-title {
  text-align: center;
  margin-bottom: 10px;
}

.home-steps-consultation-title,
.specialists-title,
.home-testimonials-title,
.faq-container-title{
  text-align: center;
  margin-bottom: 20px;
  color: var(--verde);
  font-size: 1.5rem;
}

.specialists-wrapper {
  position: relative;
  padding: 0 30px;

}

.specialists-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  background-color: #fff;
}

.specialists-card:hover {
  transform: translateY(-5px);
}

.specialist-avatar img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px;
}

.specialists-card h3 {
  font-size: 1.5rem;
  color: var(--verde);
  margin-bottom: 5px;
}

.specialist-description {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
}

#crm {
  font-size: 0.9rem;
  color: var(--cinza);
  margin-bottom: 5px;
}

#specialty {
  font-size: 1rem;
  color: var(--verde);
  font-weight: 600;
  margin-bottom: 5px;
}

.specialist-data {
  flex-direction: row;
  align-items: left;
  margin-right: 15px;
}

.specialist-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}

.specialists-info {
  display: flex;
  flex-direction: column;
  text-align: justify;
  font-size: 0.9rem;
  color: var(--cinza);
}

.btn-consultar {
  text-decoration: none;
  background-color: var(--verde);
  color: var(--branco);
  padding: 0.5rem 1rem;
  margin-top: 10px;
  border: none;
  outline: none;
  border-radius: 15px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}

.btn-consultar:hover {
  box-shadow: 0 0 2px var(--preto);
  color: var(--cinza-claro);
}
#specialist-formation {
  margin-bottom: 5px;
}

#specialist-description {
  margin-bottom: 5px;
}

.home-testimonials-container {
  width: 100%;
  max-width: 900px;
  padding: 10px 10px;

}

.home-testimonials-title {
  font-size: 1.5em;
  font-weight: bold;
  color: var(--verde);
  text-align: center;
  margin-bottom: 2rem;
}

.home-testimonials-wrapper {
  width: 100%;
  height: auto;

}

.testimonials-card {
  background: #fff;
  border-radius: 8px;
  padding: 10px 10px;
  margin: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;
}

.testimonials-stars {
  margin-bottom: 15px;
}

.star {
  color: #ffc107;
  font-size: 18px;
  margin-right: 2px;
}

.star-filled:before {
  content: "★";
}

.star-empty:before {
  content: "☆";
}

.testimonials-text {
  font-style: italic;
  margin-bottom: 15px;
  line-height: 1.5;
  flex-grow: 1;
}

.testimonials-author {
  display: flex;
  align-items: center;
  margin-top: auto;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
  overflow: hidden;
  border: 2px solid #e0e0e0;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.faq-container {
  width: 100%;
  /* max-width: 800px; */
  padding: 10px 10px;
}

.faq-question {
  display: flex;
  flex-direction: column;
  align-items: left;
  background: var(--verde);
  color: #ffffff;
  padding: 5px 5px;
  border-radius: 5px;
  margin: 3px 3px;
  cursor: pointer;
}

.icone-faq i {
  font-size: 1rem;
}

.faq-answer {
  padding: 5px 5px;
  border-radius: 5px;
  background: #ffffff;
  color: var(--verde);
}

/* Estilizando a navegação e paginação */
.specialists-pagination,
.testimonials-pagination {
  position: relative;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.specialists-navigation {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  z-index: 10;
  pointer-events: none;
}

.testimonials-navigation {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 10;
  pointer-events: none;
}

.home-testimonials-wrapper {
  position: relative; /* Adiciona um contexto para o posicionamento absoluto */
  padding: 0 50px; /* Evita que os botões fiquem colados nas bordas */
}

.testimonials-button-prev,
.testimonials-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: auto;
}

.testimonials-button-prev {
  left: -20px; /* Ajuste fino para evitar sobreposição */
}

.testimonials-button-next {
  right: -20px; /* Ajuste fino para evitar sobreposição */
}

.specialists-button-prev,
.specialists-button-next,
.testimonials-button-prev,
.testimonials-button-next {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--verde);
  color: white;
  border-radius: 50%;
  cursor: pointer;
  pointer-events: auto;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.specialists-button-prev {
  margin-left: -15px;
}

.specialists-button-next {
  margin-right: -15px;
}

.specialists-button-prev:hover,
.specialists-button-next:hover,
.testimonials-button-prev:hover,
.testimonials-button-next:hover {
  opacity: 1;
}

/* Estilizando os bullets de paginação do Swiper */
:deep(.swiper-pagination-bullet) {
  width: 10px;
  height: 10px;
  background-color: #ccc;
  opacity: 0.7;
}

:deep(.swiper-pagination-bullet-active) {
  background-color: var(--verde);
  opacity: 1;
}

/* Animações */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.4s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}

/* Responsividade */
@media (max-width: 768px) {
  .step {
    padding: 0 5px;
  }

  .home-step-label span {
    font-size: 0.75rem;
  }

  .home-step-connector {
    width: calc(100% - 10px);
  }
}

@media (max-width: 640px) {
  .home-steps-progress {
    margin: 0 20px 1.5rem 20px;
  }

  .home-step-icon {
    width: 35px;
    height: 35px;
  }

  .home-step-label span {
    font-size: 0.7rem;
  }

  .home-step-description-text {
    font-size: 0.75rem;
    line-height: 1.5;
  }
}

@media (max-width: 480px) {
  .home-step-connector {
    width: 100%;
  }

  .home-container {
    flex-direction: column-reverse;
    align-items: center;
  }

  .home-aside-container {
    width: 300px;
    padding: 5px 0;
    margin: 0;
  }
}
</style>
